import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Box } from "theme-ui";
import { ClaimForm } from "../components/ClaimForm";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "claim-your-digital-copy"
    }}>{`Claim your digital copy`}</h1>
    <p>{`Access interactive features, clickable links, copypasta code, and the latest updates.`}</p>
    <p><lite-youtube videoid="9hhEorVr5Mk" autoload></lite-youtube></p>
    <br />
    <p>{`Hello 👋`}</p>
    <p>{`You came here from the back cover of Serverless Handbook, or one of the prompts offering interactive features. I hope that means you're enjoying the book :)`}</p>
    <p>{`As promised, the live digital edition is included with your purchase.`}</p>
    <p>{`Same content as the Kindle or Paperback, except the gifs move, the emojis all work, the code is copypasta, and links are clickable. I know there's some real big links in the book 😅`}</p>
    <p>{`My favorite interactive feature right now are the cloud function test widgets embedded in some chapters. Quick way to test what you're building.`}</p>
    <h2 {...{
      "id": "heres-how-it-works"
    }}>{`Here's how it works`}</h2>
    <ol>
      <li parentName="ol">{`Share a photo of your book on `}<a parentName="li" {...{
          "href": "https://twitter.com/intent/tweet?text=Learning%20about%20%23serverless%20from%20@swizec's%20new%20Serverless%20Handbook%20and%20...%20https%3A%2F%2Fserverlesshandbook.dev"
        }}>{`twitter`}</a>{`, `}<a parentName="li" {...{
          "href": "https://facebook.com/sharer.php?u=https%3A%2F%2Fserverlesshandbook.dev"
        }}>{`facebook`}</a>{`, `}<a parentName="li" {...{
          "href": "https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fserverlesshandbook.dev"
        }}>{`linkedin`}</a>{`, or your favorite place to share things`}</li>
      <li parentName="ol">{`Add a few thoughts`}</li>
      <li parentName="ol">{`Submit this form 👇`}</li>
    </ol>
    <ClaimForm mdxType="ClaimForm" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`You'll get an account on ServerlessHandbook.dev with lifetime access`}</li>
    </ol>
    <p>{`If you don't feel like sharing, email me at `}<a parentName="p" {...{
        "href": "mailto:hi@swizec.com"
      }}>{`hi@swizec.com`}</a></p>
    <p>{`Cheers,`}<br />{`
~Swizec`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      